export const strict = false
import marks from '~/apollo/queries/marks'
import settings from '~/apollo/queries/settings'
import {findDomain} from '../app/variables';
import punycode from "punycode";



export const state = () => ({
    site_id: null,
    domain: '',
    api: "",
})
export const getters = {
    site_id: (state) => {
        return state.site_id
    },
    // isMoscow:(state) =>{
    //    return state.site_id === 21 || state.site_id === 28
    // },
    domain: (state) => {
        return state.domain
    },
    api: (state) => {
      return state.api;
    },
}
export const actions = {
    async nuxtServerInit({ dispatch, commit, state }, { req, app, $config }) {
        let currentDomain = findDomain(req.headers.host);

        await commit("SET_SITE_ID", currentDomain.siteId);
        await commit("SET_DOMAIN", "https://" + punycode.toUnicode(currentDomain.domain));
        await commit("SET_API", currentDomain.api);

        let client = app.apolloProvider.defaultClient
        // TODO получаю дефолтный процент тут так как в state нет экземпляра контекста
        commit('banks/SET_PERCENT', 4.9)
        // // TODO танцы с бубнами
        // }
        //TODO НАСТРОЙКИ САЙТА
        let currentSettings = {}
        let settingsResponse = await client.query(
            {
                query: settings,
                variables: { site_id: state.site_id }
            })
        settingsResponse.data.settings.settings.map(value => {
            currentSettings[value.key] = value.value
        })
        commit('settings/settings/SET_SETTINGS', currentSettings)
        // TODO ПОЛУЧАЮ СПИСОК МАРОК
        let response = await client.query(
            {
                query: marks,
                variables: { site_id: state.site_id, category: 'cars' }
            })
        commit('marks/marks/SET_ALL_MARKS', response.data.marks)
    },
    async request({ state }, { query, variables }) {
        let assignVariables = {
            site_id: state.site_id
        }
        let client = this.app.apolloProvider.defaultClient
        let params = { ...assignVariables, ...variables }
        return await client.query(
            {
                query: query,
                variables: this.$removeEmptyObjects(params),
                fetchPolicy: 'no-cache'
            })
    }
}
export const mutations = {
    SET_SITE_ID(state, data) {
        state.site_id = data
    },
    SET_DOMAIN(state, data) {
        state.domain = data
    },
    SET_API(state, data) {
      state.api = data
    },
}
