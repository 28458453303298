import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2b52690c = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _5e9576b8 = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _2cb5406b = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _c99bbd14 = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "pages/Contacts" */))
const _a09c3c72 = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _6283615f = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _b99a3174 = () => interopDefault(import('../pages/Exchange.vue' /* webpackChunkName: "pages/Exchange" */))
const _4034aac4 = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _08c66700 = () => interopDefault(import('../pages/Installments/index.vue' /* webpackChunkName: "pages/Installments/index" */))
const _097ab4b5 = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _01f903c8 = () => interopDefault(import('../pages/Reviews.vue' /* webpackChunkName: "pages/Reviews" */))
const _6176d8a8 = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _11d798aa = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _dac6f342 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0e42faed = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _5c002a0a = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _e3b785b6 = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _2c8ce215 = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _2b52690c,
    name: "About"
  }, {
    path: "/Banks",
    component: _5e9576b8,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _2cb5406b,
    name: "Buyout"
  }, {
    path: "/Contacts",
    component: _c99bbd14,
    name: "Contacts"
  }, {
    path: "/Cookie",
    component: _a09c3c72,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _6283615f,
    name: "Credit"
  }, {
    path: "/Exchange",
    component: _b99a3174,
    name: "Exchange"
  }, {
    path: "/Favorites",
    component: _4034aac4,
    name: "Favorites"
  }, {
    path: "/Installments",
    component: _08c66700,
    name: "Installments"
  }, {
    path: "/Privacy",
    component: _097ab4b5,
    name: "Privacy"
  }, {
    path: "/Reviews",
    component: _01f903c8,
    name: "Reviews"
  }, {
    path: "/Thanks",
    component: _6176d8a8,
    name: "Thanks"
  }, {
    path: "/Credit/:bank",
    component: _11d798aa,
    name: "Credit-bank"
  }, {
    path: "/",
    component: _dac6f342,
    name: "index"
  }, {
    path: "/:category",
    component: _0e42faed,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _5c002a0a,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _e3b785b6,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _2c8ce215,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
